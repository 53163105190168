import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { authStore } from "../../store/authStore";
import { useNavigate } from "react-router-dom";

const TestHomePage = () => {
  const navigate = useNavigate()
  const name = authStore((state) => state.name);
  const email = authStore((state) => state.email);
  const logout = authStore((state) => state.logout);

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      navigate("/");
    }, 3000);
  };

  return (
    <Container maxW="container.md" textAlign="center">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Heading size="2xl" mb={4} color="gray.700">
            Test Landing Page
          </Heading>

          <Text fontSize="xl" color="gray.500">
            This is just a test page, because things are still in development
            but here are some of your account details {name}
          </Text>

          <Box mt={4}>
            <Text fontSize="l" color="gray.500">
              Account email: {email}
              Number of review accounts integrated: 0
            </Text>

            <Text fontSize="l" color="gray.500" mt={2}>
              Number of review accounts integrated: 0
            </Text>

            <Text
              fontSize="l"
              color="gray.500"
              mt={2}
              as={Button}
              onClick={handleLogout}
            >
              Logout
            </Text>
          </Box>
        </VStack>
      </Center>
    </Container>
  );
};

export default TestHomePage;
