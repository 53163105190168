import { Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import RegisterPage from "./components/pages/RegisterPage";
import { authStore } from "./store/authStore";
import TestHomePage from "./components/pages/TestHomePage";
import ProtectedRoute from "./routes/ProtectedRoutes";

function App() {
  const isAuth = authStore((state) => state.isAuth);

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route element={<ProtectedRoute isAllowed={isAuth} />}>
          <Route path="/home" element={<TestHomePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
