import axios from "axios";
import { BACKEND_API } from "../../api/apis";
import { REGISTER_ENDPOINT, LOGIN_ENDPOINT } from "../../api/endpoints";

const registerUser = async (registerData) => {
    const response = await axios.post(BACKEND_API + REGISTER_ENDPOINT, registerData)
    return response.data;
}

const loginUser = async (loginData) => {
    const response = await axios.post(BACKEND_API + LOGIN_ENDPOINT, loginData)
    return response.data
}

const authService = {
    registerUser,
    loginUser
}

export default authService