import React from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import authService from "../../services/auth/authService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createSuccessToast, createFailureToast } from "../toasts/toasts";

const RegisterForm = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useForm();

  const registerUser = async (registerData) => {
    const userRegistered = await authService.registerUser(registerData);
    return userRegistered;
  };

  const { mutate, error } = useMutation(registerUser, {
    onSuccess: (data) => {
      const successToast = createSuccessToast(
        "Successfully Registered",
        data.message
      );
      toast({
        title: successToast.title,
        description: successToast.description,
        status: successToast.status,
        duration: successToast.duration,
      });
      setTimeout(() => {
        navigate("/");
      }, 9000);
    },
  });

  const handleRegister = async (values) => {
    const registerData = {
      name: values.name,
      email: values.email,
      category: values.category,
      password: values.password,
    };

    mutate(registerData);

    if (error) {
      const failedToast = createFailureToast(
        "Error Registering you",
        error.reponse
      );
      toast({
        title: failedToast.title,
        description: failedToast.description,
        status: failedToast.status,
        duration: failedToast.duration,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <FormControl mt={4}>
        <FormLabel> Name </FormLabel>
        <Input
          id="nameLogin"
          type="text"
          placeholder="John"
          autoFocus
          {...register("text", {
            required: "Please enter a name",
          })}
          aria-invalid={errors.name ? "true" : "false"}
        />
        <FormErrorMessage>
          {errors.name && errors.name?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel> Email </FormLabel>
        <Input
          id="emailLogin"
          type="email"
          placeholder="test@example.com"
          autoFocus
          {...register("email", {
            required: "Please enter an email",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
          aria-invalid={errors.email ? "true" : "false"}
        />
        <FormErrorMessage>
          {errors.email && errors.email?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel> Category of Business </FormLabel>
        <Input
          id="categoryLogin"
          type="text"
          placeholder="Restaurant"
          autoFocus
          {...register("text", {
            required: "Please enter a category",
          })}
          aria-invalid={errors.category ? "true" : "false"}
        />
        <FormErrorMessage>
          {errors.category && errors.category?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel> Password </FormLabel>
        <Input
          id="passwordLogin"
          type="password"
          autoFocus
          {...register("password", {
            required: "Please enter a password",
            minLength: {
              value: 6,
              message: "Miniumum password length is 6 characters",
            },
          })}
          aria-invalid={errors.password ? "true" : "false"}
        />
        <FormErrorMessage>
          {errors.password && errors.password?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel> Confirm Password </FormLabel>
        <Input
          id="confirmedPasswordLogin"
          type="password"
          autoFocus
          {...register("confirmPassword", {
            required: "Please enter a password",
            minLength: {
              value: 6,
              message: "Miniumum password length is 6 characters",
            },
            validate: (value) => {
              if (watch("password") !== value) {
                return "Passwords don't match";
              }
            },
          })}
          aria-invalid={errors.password ? "true" : "false"}
        />
        <FormErrorMessage>
          {errors.confirmPassword && errors.confirmPassword?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl mt={4}>
        <ChakraLink mt={4} as={ReactRouterLink} to="/">
          Already have an account? Login Here.
        </ChakraLink>
      </FormControl>

      <Button mt={4} isLoading={isSubmitting} type="submit" width="100%">
        Login
      </Button>
    </form>
  );
};

export default RegisterForm;
