export const createSuccessToast = (title, description) => {
    const success = {
        title: title,
        description: description,
        status: 'success',
        duration: 9000
    }   
    
    return success;
}

export const createFailureToast = (title, description) => {
    const success = {
        title: title,
        description: description,
        status: 'success',
        duration: 9000
    }   
    
    return success;
}

export const createWarningToast = (title, description) => {
    const success = {
        title: title,
        description: description,
        status: 'success',
        duration: 9000
    }   
    
    return success;
}