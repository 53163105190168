import {
  Box,
  Center,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

import LoginForm from "../forms/LoginForm";

const LandingPage = () => {
  return (
    <Container maxW="container.md" textAlign="center">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Heading size="2xl" mb={4} color="gray.700">
            Responding to reviews has never been easier.
          </Heading>

          <Text fontSize="xl" color="gray.500">
            Businesses use Easy Peasy Reviews to respond to all of their reviews
            in a single click.
          </Text>

          <Box mt={4}>
            <LoginForm />
          </Box>
        </VStack>
      </Center>
    </Container>
  );
};

export default LandingPage;
