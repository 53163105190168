import {
  Box,
  Center,
  Container,
  Heading,
  VStack,
} from "@chakra-ui/react";

import RegisterForm from "../forms/RegisterForm";

const RegisterPage = () => {
  return (
    <Container maxW="container.md" textAlign="center">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Heading size="2xl" mb={4} color="gray.700">
            Easy Peasy Reviews - Register
          </Heading>

          <Box mt={4}>
            <RegisterForm/>
          </Box>
        </VStack>
      </Center>
    </Container>
  )
}

export default RegisterPage